import React, { Component } from 'react'

import CabinetPage from '../containers/CabinetPage'
import PrimaryLayout from '../components/layouts/PrimaryLayout'
import pagesInfos from '../config/i18n/pagesInfos'

const Cabinet = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentCabinet}
    >
      <CabinetPage />
    </PrimaryLayout>
  )
}

export default Cabinet
