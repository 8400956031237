import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { Link, Element } from 'react-scroll'
import Img from 'gatsby-image'
import Iframe from 'react-iframe'
import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'
import { cover } from 'polished'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Section from '../components/Section'
import Heading from '../components/Heading'
import Hero from '../components/Hero'
import Button from '../components/Button'
import Team from '../components/Team'
import Gallery from '../components/Gallery'
import Slider from '../components/Slider'
import Map from '../components/Map'
import {
  defaultState,
  slides,
  draggedSpringOptions,
  trailingSpringOptions,
} from '../components/Slider/data'

import GatsbyLink from '../components/Link'

import pagesInfos from '../config/i18n/pagesInfos'

const offset = 30

const useStyles = makeStyles(theme => ({
  eventHeader: {
    height: '150px',
    padding: theme.spacing(2),
    backgroundPosition: 'top',
    borderRadius: '10px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    position: 'relative',
    textTransform: 'uppercase',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  eventHeaderCover: {
    ...cover(),
    backgroundColor: theme.palette.primary.main,
    opacity: 0.6,
    zIndex: -1,
  },
  h3: {
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(6),
  },
  slider: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6),
  },
  iframe: {
    display: 'block',
    border: 0,
    height: '400px',
    boxShadow:
      '0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6)',
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
    outline: '8px solid ' + theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
  imageBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: offset,
    bottom: offset,
    left: offset,
    right: offset,
    //borderRadius: '8px',
    zIndex: -1,

    border: 'solid 8px ' + theme.palette.secondary.main,
  },
  iframeWrapper: {
    position: 'relative',
    overflow: 'hidden',
    paddingTop: '56.25%',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
  },
}))

const ImplantologiePage = ({}) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const [index, setIndex] = React.useState(0)
  const data = useStaticQuery(graphql`
    query {
      bgCabinet: file(
        relativePath: {
          eq: "home/modern-metallic-dentist-tools-and-burnishers-on-a-6ZPKC39.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Helmet
        title={t('cabinet:head.title')}
        meta={[
          {
            name: 'description',
            content: t('cabinet:head.description'),
          },
        ]}
      />
      <Element id="accueil" name="accueil">
        <Hero title={t('cabinet:title')} variant="secondary"></Hero>
      </Element>

      <Section bg="#fafdfd">
        <Heading title={t('cabinet:section1.title')} variant="secondary" />
        {t('cabinet:section1.paragraphs1', { returnObjects: true }).map(
          paragraph => (
            <Typography component="p" variant="body2" paragraph key={paragraph}>
              {paragraph}
            </Typography>
          )
        )}
      </Section>

      <Section bg="#fff">
        <Heading title={t('cabinet:section2.title')} variant="secondary" />
        <Slider />
      </Section>
      <Section bg="#fafdfd">
        <Heading title={t('cabinet:section3.title')} variant="secondary" />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div className={classes.iframeWrapper}>
              <Iframe
                src="https://www.google.com/maps/embed?pb=!4v1582304740559!6m8!1m7!1sF%3A-M8hVrTpfJHU%2FWWYxHNJA79I%2FAAAAAAADlcc%2FfZNQnVy7Aq472rlJ1ScJNWNUtNnBbobfACLIBGAYYCw!2m2!1d50.82683000928!2d4.4568210353399!3f120!4f0!5f0.7820865974627469"
                width="100%"
                height="100%"
                className={classes.iframe}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.iframeWrapper}>
              <Iframe
                src="https://www.google.com/maps/embed?pb=!4v1582304907307!6m8!1m7!1sCAoSLEFGMVFpcFBRTUdvSXo4RHFnY0xUNTNTOXN3angtQTN5TG04UjR1UkFNbVZF!2m2!1d50.82682052719!2d4.4567486452941!3f60!4f0!5f0.7820865974627469"
                width="100%"
                height="100%"
                className={classes.iframe}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.iframeWrapper}>
              <Iframe
                src="https://www.google.com/maps/embed?pb=!4v1582304953302!6m8!1m7!1sCAoSLEFGMVFpcE5IanY0UTFPS2tLQ0JNd3F4YmpSYnJMTjhab29FYjNfejNOZGdJ!2m2!1d50.8268446!2d4.4566602!3f60!4f10!5f0.7820865974627469"
                width="100%"
                height="100%"
                className={classes.iframe}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.iframeWrapper}>
              <Iframe
                src="https://www.google.com/maps/embed?pb=!4v1582304991269!6m8!1m7!1sCAoSLEFGMVFpcE00UTB1X3lSOTJDVEkwWVFRZERGSGt3TTgxRTRlbTdWNGZGTVNM!2m2!1d50.826827444666!2d4.4567895156031!3f140!4f0!5f0.7820865974627469"
                width="100%"
                height="100%"
                className={classes.iframe}
              />
            </div>
          </Grid>
        </Grid>
      </Section>
    </React.Fragment>
  )
}

ImplantologiePage.defaultProps = {}

export default ImplantologiePage
